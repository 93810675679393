import { primaryColor } from 'assets/theme/colors';
import PageContainerBase from 'components/PageContainer';
import styled from 'styled-components';

import SubHeadingBase from '../SubHeading';

export const Wrapper = styled.div`
  background: #f8fafc;
`;

export const PageContainerModified = styled(PageContainerBase)`
  padding-bottom: 30px;
`;

export const SubHeading = styled(SubHeadingBase)`
  text-align: center;
  padding: 30px 15px 0;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
  min-height: 32px;
  text-decoration: none;
`;

export const Description = styled.div`
  text-align: center;
  font-size: 1.2em;
  padding: 15px 0;
  & strong {
    color: ${primaryColor};
  }
  & span {
    font-weight: normal;
  }
`;

export const List = styled.ul`
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px 0px 0px;
  list-style-type: none;
`;

export const BulletPoint = styled.li`
  width: 33%;
  padding: 10px 20px;
  text-align: center;
  font-weight: normal;
  & p {
    margin: 15px 0;
    font-size: 1em;
  }
`;

export const BulletPointIcon = styled.img`
  margin-right: 15px;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
`;
