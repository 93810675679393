import Button from '@material-ui/core/Button';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { createRef, FunctionComponent, RefObject } from 'react';

// core components
import buttonStyle from './buttonStyle';

const makeComponentStyles = makeStyles(buttonStyle);

interface Color {
  primary: string;
  info: string;
  success: string;
  warning: string;
  danger: string;
  rose: string;
  white: string;
  transparent: string;
}

interface Size {
  sm: string;
  md: string;
  lg: string;
}

interface Props {
  color?: keyof Color;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  simple?: boolean;
  size?: keyof Size;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  className?: string;
  ref?: RefObject<HTMLButtonElement>;
  [x: string]: any;
}

const RegularButton: FunctionComponent<Props> = ({
  size = 'sm',
  color = 'primary',
  round = true,
  fullWidth = false,
  disabled = false,
  simple = true,
  block = false,
  link = false,
  justIcon = false,
  className = '',
  ref = createRef<HTMLButtonElement>(),
  children,
  ...rest
}) => {
  const classes = makeComponentStyles();
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  return (
    <Button {...rest} ref={ref} className={btnClasses}>
      {children}
    </Button>
  );
};

export default RegularButton;
