// Assets
import headerLandingPark from 'assets/img/headerlandingcamping.svg';
import Button from 'components/CustomButtons/Button';
import Footer from 'components/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderLandingPage from 'components/Header/HeaderLandingPage';
// core components
import Theme from 'components/Theme';
import React, { FunctionComponent } from 'react';
// Mobile detect
import { isMobileOnly } from 'react-device-detect';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

import LandlordDesktopSection from './Sections/LandlordDesktopSection';
// Sections for this page
import LandlordMobileSection from './Sections/LandlordMobileSection';
import PartnersSection from './Sections/PartnersSection';
import ReinsuranceSection from './Sections/ReinsuranceSection';
import RentalDesktopSection from './Sections/RentalDesktopSection';
import RentalMobileSection from './Sections/RentalMobileSection';
// Styles
import { Container, HeaderLandingParkImage, Main, Title, Wrapper } from './style';

const LandingPagePark: FunctionComponent = () => {
  return (
    <Theme>
      <HeaderLandingPage
        fixed
        changeStyleOnScroll={{
          shadowHeight: 50,
          buttonHeight: 400,
        }}
      />
      <Wrapper>
        <Container>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Title>Vous êtes propriétaire d{"'"}un camping ?</Title>
              <h4>
                Optimisez la gestion locative de vos résidents et de vos locatifs avec izicamp ! On
                vous dis tout...
              </h4>
              <br />
              <Link to="/candidature-camping">
                <Button color="primary" size="lg">
                  Commencer
                </Button>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <HeaderLandingParkImage src={headerLandingPark} alt="" />
            </GridItem>
          </GridContainer>
        </Container>
      </Wrapper>
      <Main>
        {isMobileOnly ? (
          <React.Fragment>
            <LandlordMobileSection />
            <RentalMobileSection />
            <ReinsuranceSection />
            <PartnersSection />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LandlordDesktopSection />
            <RentalDesktopSection />
            <ReinsuranceSection />
            <PartnersSection />
          </React.Fragment>
        )}
      </Main>
      <Footer />
    </Theme>
  );
};

export default LandingPagePark;
