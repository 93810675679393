import { primaryColor } from 'assets/theme/colors';
import SwiperBase from 'components/Swiper/Swiper';
import styled from 'styled-components';

import SubHeadingBase from '../SubHeading';

export const Wrapper = styled.div`
  width: 100%;
`;

export const SubHeading = styled(SubHeadingBase)`
  font-size: 2em;
  margin-bottom: 1rem;
  padding: 30px 15px 0px;
  margin-top: 0px;
  min-height: 32px;
  text-decoration: none;
`;

export const Description = styled.p`
  font-size: 1.2em;
  & strong {
    color: ${primaryColor};
  }
  & span {
    font-weight: normal;
  }
`;

export const Swiper = styled(SwiperBase)`
  padding: 15px 0px;
`;

export const SwiperCard = styled.article`
  padding: 0px 15px;
`;

export const Drawing = styled.div`
  padding: 15px 0px;
  text-align: center;
  & img {
    max-width: 150px;
  }
`;

export const BulletPoint = styled.p`
  text-align: center;
  padding: 0px 40px;
  font-size: 1em;
`;

export const BulletPointIcon = styled.img`
  display: block;
  margin: 20px auto;
`;
