// Assets
import campsiteOwnerWondering from 'assets/img/campsiteownerwondering.svg';
import cleanlinessIcon from 'assets/img/cleanliness_icon.svg';
import phoneOperatorIcon from 'assets/img/phone_operator_icon.svg';
import secureIncomeIcon from 'assets/img/secure_income_icon.svg';
import visibilityIcon from 'assets/img/visibility_icon.svg';
// core components
import Bubble from 'components/Bubble';
import PageContainer from 'components/PageContainer';
import React, { FunctionComponent } from 'react';

// Styles
import {
  ArticleLeft,
  ArticleRight,
  BlockHeading,
  BulletPoint,
  BulletPointIcon,
  Contents,
  Description,
  Drawing,
  Solutions,
  SubHeading,
} from './style';

const LandlordDesktopSection: FunctionComponent = () => {
  return (
    <PageContainer>
      <SubHeading>izicamp.fr pour vos résidents</SubHeading>
      <BlockHeading>
        Gérer les <strong>locations</strong> de vos <strong>résidents</strong> est devenu trop
        compliqué ?
      </BlockHeading>
      <Contents>
        <ArticleLeft>
          <Description>
            Quand <strong>vous</strong> louez pour eux, <strong>ils</strong> ne sont pas contents :
          </Description>
          <Bubble>
            <p>Vous louez d{"'"}abord vos locatifs avant de louer mon mobil-home !</p>
            <p>Ah ça, pour me réclamer mon loyer, y{"'"}a du monde !</p>
            <p>
              Je ne sais jamais si c{"'"}est loué ou pas, si j{"'"}avais su, je serais venu !
            </p>
            <p>En fin de saison : Mon mobil-home est sale et cassé !</p>
          </Bubble>
        </ArticleLeft>
        <Drawing>
          <img src={campsiteOwnerWondering} alt="" />
        </Drawing>
        <ArticleRight>
          <Description>
            Quand <strong>ils</strong> louent par eux mêmes, <strong>vous</strong> n{"'"}êtes pas
            content :
          </Description>
          <Bubble>
            <p>Concurrence déloyale, parité tarifaire non respectée.</p>
            <p>Absence de visibilité sur les vacanciers non déclarés.</p>
            <p>Perte de temps avec des vacanciers mécontents</p>
            <p>Mise en péril de l{"'"}image de marque du camping.</p>
          </Bubble>
        </ArticleRight>
      </Contents>
      <BlockHeading>
        Réglez ces problèmes grâce à{' '}
        <strong>
          izicamp<span>.fr</span>
        </strong>{' '}
        et tout le monde sera content !
      </BlockHeading>
      <Solutions>
        <BulletPoint>
          <BulletPointIcon src={secureIncomeIcon} alt="" />
          <p>
            <strong>Revenus locatifs optimisés et sécurisés.</strong>
          </p>
        </BulletPoint>
        <BulletPoint>
          <BulletPointIcon src={visibilityIcon} alt="" />
          <p>
            <strong>Visibilité en temps réel sur les remplissages et les revenus.</strong>
          </p>
        </BulletPoint>
        <BulletPoint>
          <BulletPointIcon src={cleanlinessIcon} alt="" />
          <p>
            <strong>Propreté et intégrité du mobil-home assurés.</strong>
          </p>
        </BulletPoint>
        <BulletPoint>
          <BulletPointIcon src={phoneOperatorIcon} alt="" />
          <p>
            <strong>Service client disponible 7j/7 de 10h à minuit.</strong>
          </p>
        </BulletPoint>
      </Solutions>
    </PageContainer>
  );
};

export default LandlordDesktopSection;
