// Create AppBar components before
import PhoneIconBase from '@material-ui/icons/Phone';
import ButtonBase from 'components/CustomButtons/Button';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

export const Button = styled(ButtonBase)``;

export const Flex = styled.div`
  display: flex;
`;

export const PhoneNumber = styled.a`
  display: inline-flex;
  padding: 8px 2px 8px 2px;
  ${!isMobileOnly ? 'margin-left: 30px; padding-right: 9px' : ''};
  border: 1px solid #38404d;
  border-radius: 5px;
  &:hover {
    background: #38404d1a;
  }
`;

export const PhoneNumberIcon = styled(PhoneIconBase)`
  width: 40px;
`;
