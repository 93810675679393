// @material-ui/core components
import { GridSize } from '@material-ui/core/Grid';
import React, { FunctionComponent } from 'react';

import { Item } from './style';

interface Props {
  xs: GridSize;
  sm: GridSize;
  md: GridSize;
}

const GridItem: FunctionComponent<Props> = ({ children, xs, sm, md, ...rest }) => (
  <Item item xs={xs} sm={sm} md={md} {...rest}>
    {children}
  </Item>
);

export default GridItem;
