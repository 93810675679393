// @material-ui/core components
import { Checkbox, FormControl, FormLabel, Radio, RadioGroup } from '@material-ui/core';
// core components
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { FunctionComponent, ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

import { FormControlLabel, SecondaryField, SelectInterface } from './style';

interface WatchFields {
  interestTourOperator: boolean;
  interestAccommodationManagement: boolean;
}

interface Props {
  control: Control;
  watchFields: WatchFields;
}

const ServicesFormSection: FunctionComponent<Props> = ({ control, watchFields }) => {
  return (
    <div>
      <h2>Je suis intéressé par izicamp pour :</h2>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel
            control={
              <Controller
                name="interestTourOperator"
                control={control}
                render={(props): ReactElement => (
                  <Checkbox
                    color="primary"
                    onChange={(e): void => props.onChange(e.target.checked)}
                    checked={props.value}
                  />
                )}
              />
            }
            label="La commercialisation et promotion de mes séjours et prestations"
          />
          {watchFields.interestTourOperator && (
            <SelectInterface>
              <FormControl component="fieldset">
                <FormLabel focused={false}>
                  Par quel service souhaitez-vous que nous récupérions vos disponibilités ?
                </FormLabel>
                <Controller
                  as={
                    <RadioGroup aria-label="passerelle" name="interface">
                      <FormControlLabel value="nothing" control={<Radio />} label="Aucun" />
                      <FormControlLabel
                        value="sequoiasoft"
                        control={<Radio />}
                        label="Sequoiasoft"
                      />
                      <FormControlLabel value="inaxel" control={<Radio />} label="Inaxel" />
                      <FormControlLabel
                        value="secureholiday"
                        control={<Radio />}
                        label="Secureholiday"
                      />
                      <FormControlLabel value="other" control={<Radio />} label="Autre" />
                    </RadioGroup>
                  }
                  name="interface"
                  control={control}
                />
              </FormControl>
            </SelectInterface>
          )}
          <FormControlLabel
            control={
              <Controller
                name="interestAccommodationManagement"
                control={control}
                render={(props): ReactElement => (
                  <Checkbox
                    color="primary"
                    onChange={(e): void => props.onChange(e.target.checked)}
                    checked={props.value}
                  />
                )}
              />
            }
            label="La gestion locative pour le compte de mes résidents"
          />
          {watchFields.interestAccommodationManagement && (
            <SecondaryField>
              <CustomInput
                name="residentsNumber"
                control={control}
                labelText="Nombres de résidents"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'number',
                  name: 'residentsNumber',
                  min: 0,
                  inputProps: { min: 0 },
                }}
              />
            </SecondaryField>
          )}
          <FormControlLabel
            control={
              <Controller
                name="interestSeller"
                control={control}
                render={(props): ReactElement => (
                  <Checkbox
                    color="primary"
                    onChange={(e): void => props.onChange(e.target.checked)}
                    checked={props.value}
                  />
                )}
              />
            }
            label="La vente et renouvellement de mobil-homes de mes résidents"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ServicesFormSection;
