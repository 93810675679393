import GridBase from '@material-ui/core/Grid';
import styled from 'styled-components';

export const Item = styled(GridBase)`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: auto;
`;
