// Assets
import desktopLogo from 'assets/img/logoWithText.svg';
import mobileLogo from 'assets/img/mobilelogo.svg';
// Core components
import Header, { ChangeStyleOnScroll } from 'components/Header';
import React, { FunctionComponent } from 'react';
// Mobile detect
import { isMobileOnly } from 'react-device-detect';

// Styles
import { Flex, PhoneNumber, PhoneNumberIcon } from './style';

interface Props {
  changeStyleOnScroll: ChangeStyleOnScroll;
  fixed: boolean;
  absolute?: boolean;
}

const HeaderLandingPage: FunctionComponent<Props> = ({
  changeStyleOnScroll,
  fixed,
  absolute = false,
}) => {
  return (
    <Header fixed={fixed} absolute={absolute} changeStyleOnScroll={changeStyleOnScroll}>
      <Flex>
        <a href="/">
          <img
            src={isMobileOnly ? mobileLogo : desktopLogo}
            alt=""
            width={isMobileOnly ? '44px' : '150px'}
          />
        </a>
      </Flex>
      <PhoneNumber href="tel:+33240883612">
        <PhoneNumberIcon />
        {!isMobileOnly && '02 40 88 36 12'}
      </PhoneNumber>
    </Header>
  );
};

export default HeaderLandingPage;
