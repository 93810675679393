import { ApolloProvider, gql, useMutation, useQuery } from '@apollo/client';
import Error from 'components/Error';
// core components
import Footer from 'components/Footer';
import HeaderForm from 'components/Header/HeaderForm';
import Spinner from 'components/Spinner';
import Theme from 'components/Theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Lib
import client from '../../graphql';
// Sections for this page
import SignSummarySection from './Sections/SignSummarySection';
import SignTermsSection from './Sections/SignTermsSection';
// Styles
import { Container, Main, Title } from './style';
// Types
import { FormValues } from './types';

const FIND_PARK_APPLICATION = gql`
  query findParkApplication($id: ID!) {
    findParkApplication(id: $id) {
      parkId
      firstName
      lastName
      email
      phone
      name
      chain
      ranking
      postcode
      line1
      line2
      city
      interface
      interestTourOperator
      interestAccommodationManagement
      residentsNumber
      interestSeller
      source
      signedAt
      comment
      immediatePayout
      advertisingCampaign
      companyName
      companyLine1
      companyLine2
      companyCity
      companyPostcode
      country
    }
  }
`;

const SIGN_PARK_CONTRACT = gql`
  mutation signParkContract($parkApplicationId: ID!, $data: SignParkContractInput!) {
    signParkContract(parkApplicationId: $parkApplicationId, data: $data)
  }
`;

const ParkApplicationSignature: FunctionComponent = () => {
  const history = useHistory();
  const year = new Date().getFullYear();
  const { id } = useParams<{ id: string }>();
  const [mutationError, setMutationError] = useState(false);
  const {
    loading,
    error: queryError,
    data,
  } = useQuery(FIND_PARK_APPLICATION, {
    variables: { id },
    client,
  });
  const [signParkContract] = useMutation(SIGN_PARK_CONTRACT, { client });

  useEffect(() => {
    if (data && data.findParkApplication && data.findParkApplication.signedAt) {
      history.push(`/remerciements`);
    }
  }, [data]);

  // Submit Form
  const handleSubmit = async (values: FormValues): Promise<void> => {
    const payload = {
      ...values,
      immediatePayout: values.immediatePayout === 'true',
      advertisingCampaign: values.advertisingCampaign === 'true',
    };
    const result = await signParkContract({ variables: { parkApplicationId: id, data: payload } });
    if (payload && result.data && result.data.signParkContract) {
      history.push('/remerciements');
    } else {
      setMutationError(true);
    }
  };

  const displayContent = () => {
    if (loading) return <Spinner />;
    if (queryError || mutationError) {
      return (
        <Error
          message={
            <p>
              Une erreur est survenue, merci de contacter le support{' '}
              <a href="mailto:contact@izicamp.fr">contact@izicamp.fr</a>
            </p>
          }
        />
      );
    }
    return (
      <>
        <SignSummarySection park={data.findParkApplication} />
        <SignTermsSection park={data.findParkApplication} onSubmit={handleSubmit} />
      </>
    );
  };

  return (
    <Theme>
      <HeaderForm
        fixed
        changeStyleOnScroll={{
          shadowHeight: 100,
          buttonHeight: 0,
        }}
      />
      <ApolloProvider client={client}>
        <Main>
          <Container>
            <Title>Contrat de partenariat Camping {year}</Title>
            {displayContent()}
          </Container>
        </Main>
        <Footer />
      </ApolloProvider>
    </Theme>
  );
};

export default ParkApplicationSignature;
