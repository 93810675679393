// Create AppBar components before
import PhoneIconBase from '@material-ui/icons/Phone';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

export const Flex = styled.div`
  flex: 1;
`;

export const PhoneNumber = styled.a`
  align-items: center;
  display: inline-flex;
  font-size: 1em;
  padding: 8px 2px 8px 2px;
  ${!isMobileOnly ? 'padding-right: 9px' : ''};
  border: 1px solid #38404d;
  border-radius: 5px;
  &:hover {
    background: #38404d1a;
  }
`;

export const PhoneNumberIcon = styled(PhoneIconBase)`
  width: 40px;
`;
