import 'assets/scss/izicamp.scss?v=1.9.0';

import ScrollToTop from 'components/ScrollToTop';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
// pages for this product
import LandingPagePark from 'views/LandingPages/LandingPagePark';
import ParkApplicationForm from 'views/ParkApplicationForm';
import ParkApplicationSignature from 'views/ParkApplicationSignature';
import ThankYouPage from 'views/ThankYouPage';

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop />
    <Switch>
      <Route path="/" exact component={LandingPagePark} />
      <Route path="/candidature-camping" component={ParkApplicationForm} />
      <Route path="/signature/:id" component={ParkApplicationSignature} />
      <Route path="/remerciements" component={ThankYouPage} />
    </Switch>
  </Router>,
  document.getElementById('root')
);
