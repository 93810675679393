import { primaryColor } from 'assets/theme/colors';
import SwiperBase from 'components/Swiper/Swiper';
import styled from 'styled-components';

import SubHeadingBase from '../SubHeading';

export const Wrapper = styled.div`
  background: #f8fafc;
`;

export const SubHeading = styled(SubHeadingBase)`
  padding: 30px 15px 0px;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0px;
  min-height: 32px;
  text-decoration: none;
`;

export const Swiper = styled(SwiperBase)`
  padding: 15px 0px;
`;

export const SwiperCard = styled.article`
  padding: 0px 15px;
`;

export const Description = styled.div`
  font-size: 1.2em;
  & strong {
    color: ${primaryColor};
  }
  & span {
    font-weight: normal;
  }
`;

export const Drawing = styled.div`
  max-width: 500px;
  margin: 0px auto;
  padding: 15px 10%;
`;

export const List = styled.ul`
  padding: 15px 0px 0px;
`;

export const BulletPointIcon = styled.img`
  margin-right: 15px;
  width: 60px;
  height: 60px;
`;

export const BulletPoint = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  & strong {
    color: ${primaryColor};
  }
  & p {
    margin: 0px;
  }
`;
