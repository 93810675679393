import FormControlLabelBase from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

export const SelectInterface = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 30px;
`;

export const SecondaryField = styled.div`
  font-family: 'Open Sans';
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 50%;
  }
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
`;

export const FormControlLabel = styled(FormControlLabelBase)`
  font-family: 'Open Sans';
  display: flex;
`;
