import 'yup-phone';

import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
// @material-ui/icons
import SendIcon from '@material-ui/icons/Send';
// core components
import Button from 'components/CustomButtons/Button';
import MutationErrors from 'components/Error/MutationErrors';
import { omit } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
// Form library
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ParkApplicationValues } from 'types/parkApplication';
// Form validation
import * as yup from 'yup';

import AdditionalInformationsFormSection from '../AdditionalInformationsFormSection';
import CompanyAddressFormSection from '../CompanyAddressFormSection';
// Sections for this page
import ContactFormSection from '../ContactFormSection';
import ParkAddressFormSection from '../ParkAddressFormSection';
import ServicesFormSection from '../ServicesFormSection';
// import FocusOnError from "components/FocusOnError/FocusOnError.js";
import { SubmitButton } from './style';

const APPLY_PARK_APPLICATION = gql`
  mutation applyParkApplication($data: ParkApplicationInput!) {
    applyParkApplication(data: $data) {
      parkApplicationId
      success
      errors {
        type
        message
        path
      }
    }
  }
`;

const defaultValues = {
  name: '',
  chain: '',
  ranking: 0,
  postcode: '',
  city: '',
  line1: '',
  line2: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  interestTourOperator: false,
  interestAccommodationManagement: false,
  interestSeller: false,
  residentsNumber: 0,
  interface: '',
  companyName: '',
  companyPostcode: '',
  companyCity: '',
  companyLine1: '',
  companyLine2: '',
};

// here you can add multiple validations per field
const validationSchema = yup.object().shape({
  firstName: yup.string().required('Le prénom est un champ obligatoire. Veuillez le renseigner.'),
  lastName: yup.string().required('Le nom est un champ obligatoire. Veuillez le renseigner.'),
  email: yup
    .string()
    .required("L'email est un champ obligatoire. Veuillez le renseigner.")
    .email('Veuillez respecter le format email : jhonDoe@gmail.com'),
  name: yup
    .string()
    .required('Le nom du camping est un champ obligatoire. Veuillez le renseigner.'),
  line1: yup
    .string()
    .required("L'adresse du camping est un champ obligatoire. Veuillez le renseigner."),
  city: yup
    .string()
    .required('La ville du camping est un champ obligatoire. Veuillez le renseigner.'),
  postcode: yup
    .string()
    .required('Le code postal du camping est un champ obligatoire. Veuillez le renseigner.')
    .min(5, 'Veuillez respecter le format code postal : 44380')
    .max(5, 'Veuillez respecter le format code postal : 44380'),
  companyName: yup
    .string()
    .required('Le nom de la société est un champ obligatoire. Veuillez le renseigner.'),
  companyLine1: yup
    .string()
    .required("L'adresse de la société est un champ obligatoire. Veuillez le renseigner."),
  companyCity: yup
    .string()
    .required('La ville de la société est un champ obligatoire. Veuillez le renseigner.'),
  companyPostcode: yup
    .string()
    .required('Le code postal de la société est un champ obligatoire. Veuillez le renseigner.')
    .min(5, 'Veuillez respecter le format code postal : 44380')
    .max(5, 'Veuillez respecter le format code postal : 44380'),
  phone: yup.string().test('phoneNumber', 'Numéro de téléphone invalide', async (phone) => {
    if (phone === '') return true;
    return yup.string().phone().isValid(phone);
  }),
});

const RegisterParkForm: FunctionComponent = () => {
  const history = useHistory();
  const [mutationErrors, setMutationErrors] = useState(null);
  const [applyParkApplication] = useMutation(APPLY_PARK_APPLICATION, {
    onError: (err) => console.error(err),
  });

  // Form validation
  const { formState, watch, control, errors, handleSubmit } = useForm<ParkApplicationValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues,
    shouldFocusError: true,
  });

  // Submit Form
  const onSubmit = async (values: ParkApplicationValues): Promise<void> => {
    const data = {
      ...omit(values, '__typename'),
      country: 'France',
      source: 'parkApplication',
    };
    data.residentsNumber = Number(data.residentsNumber);
    const result = await applyParkApplication({ variables: { data } });
    const res = result.data.applyParkApplication;
    if (!res.success) {
      setMutationErrors(res.errors);
    } else {
      history.push(`/signature/${res.parkApplicationId}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContactFormSection control={control} formState={formState} errors={errors} />
      <AdditionalInformationsFormSection control={control} formState={formState} errors={errors} />
      <ParkAddressFormSection control={control} formState={formState} errors={errors} />
      <CompanyAddressFormSection control={control} formState={formState} errors={errors} />
      <ServicesFormSection
        control={control}
        watchFields={watch(['interestTourOperator', 'interestAccommodationManagement'])}
      />
      {mutationErrors && <MutationErrors errors={mutationErrors} />}
      <SubmitButton>
        <Button
          size="lg"
          type="submit"
          startIcon={<SendIcon />}
          color="primary"
          disabled={formState.isSubmitting}
        >
          Valider
        </Button>
      </SubmitButton>
    </form>
  );
};

export default RegisterParkForm;
