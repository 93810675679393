import { primaryColor } from 'assets/theme/colors';
import styled from 'styled-components';

import SubHeadingBase from '../SubHeading';

export const BlockHeading = styled.h3`
  padding: 20px 0 0 0;
  text-align: center;
  font-size: 1.2em;
  & strong {
    color: ${primaryColor};
  }
  & span {
    font-weight: normal;
  }
`;

export const SubHeading = styled(SubHeadingBase)`
  text-align: center;
  font-size: 2em;
  margin-bottom: 1rem;
  padding: 30px 15px 0px;
  margin-top: 0px;
  min-height: 32px;
`;

export const Contents = styled.div`
  display: inline-flex;
  justify-content: space-between;
  padding: 30px 0px;
`;

export const ArticleLeft = styled.article`
  width: 40%;
  padding-right: 30px;
`;

export const ArticleRight = styled.article`
  width: 40%;
  padding-left: 30px;
`;

export const Description = styled.p`
  font-size: 1.2em;
  & strong {
    color: ${primaryColor};
  }
  & span {
    font-weight: normal;
  }
`;

export const Drawing = styled.div`
  width: 150px;
  padding: 15px 0;
  text-align: center;
  & img {
    max-width: 150px;
  }
`;

export const Solutions = styled.ul`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  list-style-type: none;
  padding: 30px 0;
  flex-wrap: wrap;
`;

export const BulletPoint = styled.li`
  padding: 0px 40px;
  margin: 15px 20px;
  text-align: center;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  width: 230px;
  & p {
    font-size: 1em;
  }
`;

export const BulletPointIcon = styled.img`
  margin: 20px;
`;
