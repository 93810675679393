// MaterialUI components
import { Checkbox } from '@material-ui/core';
// core components
import Button from 'components/CustomButtons/Button';
import React, { FunctionComponent } from 'react';
// Form library
import { useForm } from 'react-hook-form';
import { ParkApplicationValues } from 'types/parkApplication';

// Types
import { FormValues } from '../../types';
// Style
import { BackgroundInfo, ButtonPaper, ConditionsLink, FormControlLabel, Text } from './style';
import Terms from './Terms';

interface Props {
  park: ParkApplicationValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

const SignTermsSection: FunctionComponent<Props> = ({ park, onSubmit }) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  // Form validation
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      interface: park.interface,
      immediatePayout: park.immediatePayout ? 'true' : 'false',
      advertisingCampaign: park.advertisingCampaign ? 'true' : 'false',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Terms data={park} control={control} />
      <BackgroundInfo elevation={0}>
        <FormControlLabel
          control={
            <Checkbox required={true} checked={checked} onChange={handleChange} name="checkbox" />
          }
          label={
            <Text>
              Le Propriétaire déclare avoir lu et accepte, par le présent accord,{' '}
              <ConditionsLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://izicamp.fr/build/file/conditionsg%C3%A9n%C3%A9ralescamping.pdf"
              >
                les conditions générales de Prestation
              </ConditionsLink>
              .
            </Text>
          }
        />
      </BackgroundInfo>
      <ButtonPaper elevation={0}>
        <Button type="submit" size="sm" color="success" disabled={!checked}>
          Créer mon camping
        </Button>
      </ButtonPaper>
    </form>
  );
};

export default SignTermsSection;
