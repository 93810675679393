// core components
import CustomInput from 'components/CustomInput/CustomInput';
import CustomPhoneInput from 'components/CustomInput/CustomPhoneInput';
import Error from 'components/Error';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { FunctionComponent } from 'react';
import { Control, FieldErrors, FormState } from 'react-hook-form';
import { ParkApplicationValues } from 'types/parkApplication';

interface Props {
  control: Control;
  errors: FieldErrors;
  formState: FormState<ParkApplicationValues>;
}

const ContactFormSection: FunctionComponent<Props> = ({ control, errors, formState }) => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="firstName"
            control={control}
            labelText="Prénom"
            error={errors.firstName && formState.touched.firstName}
            success={!errors.firstName && formState.touched.firstName}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'firstName',
            }}
          />
          {errors.firstName && <Error message={errors.firstName.message} />}
          <CustomInput
            name="lastName"
            control={control}
            labelText="Nom"
            error={errors.lastName && formState.touched.lastName}
            success={!errors.lastName && formState.touched.lastName}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'lastName',
            }}
          />
          {errors.lastName && <Error message={errors.lastName.message} />}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="email"
            control={control}
            labelText="Email"
            error={errors.email && formState.touched.email}
            success={!errors.email && formState.touched.email}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'email',
            }}
          />
          {errors.email && <Error message={errors.email.message} />}
          <CustomPhoneInput
            control={control}
            name="phone"
            labelText="Numéro de téléphone"
            error={errors.phone && formState.touched.phone}
            success={!errors.phone && formState.touched.phone}
            defaultCountry="fr"
            formControlProps={{
              name: 'phone',
              fullWidth: true,
            }}
            inputProps={{
              name: 'phone',
            }}
          />
          {errors.phone && <Error message={errors.phone.message} />}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ContactFormSection;
