// Assets
import desktopLogo from 'assets/img/logoWithText.svg';
import mobileLogo from 'assets/img/mobilelogo.svg';
// Core components
import Header, { ChangeStyleOnScroll } from 'components/Header';
import React, { FunctionComponent } from 'react';
// Mobile detect
import { isMobileOnly } from 'react-device-detect';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// Styles
import { Button, Flex, PhoneNumber, PhoneNumberIcon } from './style';

interface Props {
  fixed: boolean;
  absolute?: boolean;
  changeStyleOnScroll: ChangeStyleOnScroll;
}

const HeaderLandingPage: FunctionComponent<Props> = ({
  changeStyleOnScroll,
  fixed,
  absolute = false,
}) => {
  const [isButtonDisplayed, setButtonDisplay] = React.useState(false);

  const headerStyleChange = () => {
    const windowsScrollTop = window.pageYOffset;
    setButtonDisplay(windowsScrollTop > changeStyleOnScroll.buttonHeight);
  };

  React.useEffect(() => {
    if (changeStyleOnScroll) {
      window.addEventListener('scroll', headerStyleChange);
    }
    return function cleanup() {
      if (changeStyleOnScroll) {
        window.removeEventListener('scroll', headerStyleChange);
      }
    };
  });

  return (
    <Header fixed={fixed} absolute={absolute} changeStyleOnScroll={changeStyleOnScroll}>
      <a href="/">
        <img
          src={isMobileOnly ? mobileLogo : desktopLogo}
          alt="izicamp"
          width={isMobileOnly ? '44px' : '150px'}
        />
      </a>
      <Flex>
        {isButtonDisplayed && (
          <Link to="/candidature-camping">
            <Button color="primary" size="sm">
              Commencer
            </Button>
          </Link>
        )}
        <PhoneNumber href="tel:+33240883612">
          <PhoneNumberIcon />
          {!isMobileOnly && '02 40 88 36 12 '}
        </PhoneNumber>{' '}
      </Flex>
    </Header>
  );
};

export default HeaderLandingPage;
