// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// nodejs library that concatenates classes
import classNames from 'classnames';
// Styles
import { omit } from 'lodash';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { ChangeEvent, createRef, FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';

import styles from './customInputStyle';

const useStyles = makeStyles(styles);

interface FormControlProps {
  className?: string;
  fullWidth?: boolean;
  [x: string]: any;
}

interface LabelProps {
  className?: string;
  [x: string]: any;
}

interface InputProps {
  name: string;
  value?: any;
  maxLength?: number;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  [x: string]: any;
}

interface Props {
  control: Control;
  name: string;
  formControlProps?: FormControlProps;
  labelText: string;
  id?: string;
  labelProps?: LabelProps;
  inputProps?: InputProps;
  error?: boolean;
  white?: boolean;
  inputRootCustomClasses?: any;
  success?: boolean;
  defaultCountry: string;
}

const CustomPhoneInput: FunctionComponent<Props> = ({
  control,
  name,
  formControlProps,
  labelText,
  id,
  labelProps = {},
  inputProps = { ref: createRef() },
  error = false,
  white,
  inputRootCustomClasses = undefined,
  success = false,
  defaultCountry,
}) => {
  const classes = useStyles();

  const labelClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  let formControlClasses: string;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={(controlledInputProps) => (
        <MuiPhoneNumber
          {...formControlProps}
          className={formControlClasses}
          label={labelText}
          data-cy="user-phone"
          defaultCountry={defaultCountry}
          value={controlledInputProps.value}
          InputLabelProps={{
            classes: {
              root: `${classes.labelRoot} ${labelClasses}`,
            },
            htmlFor: id,
            ...labelProps,
          }}
          InputProps={{
            classes: {
              input: inputClasses,
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses,
            },
            id,
            ...inputProps,
            ...omit(controlledInputProps, 'value'),
          }}
        />
      )}
    />
  );
};

export default CustomPhoneInput;
