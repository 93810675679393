// Use common components
import PageContainer from 'components/PageContainer';
import React, { FunctionComponent } from 'react';

import logoFooter from '../../assets/img/logoFooter.svg';
import phone from '../../assets/img/phone.svg';
// Styles
import { Container, Flex, Item, Number, PhoneItem, PhoneSection, Title } from './style';

interface Props {
  whiteFont?: boolean;
}

const Footer: FunctionComponent<Props> = ({ whiteFont = false }) => {
  return (
    <Container whiteFont={whiteFont}>
      <PageContainer>
        <Title font-size={'1em'}>Izicamp</Title>
        <Item href="#">À propos</Item>
        <Item href="#">Mentions légales</Item>
        <Item href="#">Politiques cookies</Item>
        <PhoneSection>
          <Item href="tel:+33240883612">
            <img src={phone} alt="" width="44px" />{' '}
          </Item>
          <PhoneItem href="tel:+33240883612">
            <Number>02 40 88 36 12</Number>
            disponible 7j7 de 10h à minuit)
          </PhoneItem>
        </PhoneSection>
        <Flex>
          <a href="https://izicamp.fr">
            <img src={logoFooter} alt="" width="150px" />
          </a>{' '}
          {/* <Copyright>2021 Izicamp. All rights reserved</Copyright> */}
        </Flex>
      </PageContainer>
    </Container>
  );
};

export default Footer;
