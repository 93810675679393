// Assets logo png
import fnhpaLogo from 'assets/img/partners/fnhpa.png';
// Assets img svg
import partners from 'assets/img/partners/partners.svg';
import supports from 'assets/img/partners/supports.svg';
// Components
import PageContainer from 'components/PageContainer';
import React, { FunctionComponent } from 'react';

import { IMGContainer, LogoContainer, SubHeading, Wrapper } from './style';

const PartnersSection: FunctionComponent = () => {
  return (
    <Wrapper>
      <PageContainer>
        <SubHeading>Nos partenaires</SubHeading>
        <IMGContainer>
          <img src={partners} alt="nos partenaires" />
        </IMGContainer>
        <SubHeading>Nos soutiens</SubHeading>
        <IMGContainer>
          <img src={supports} alt="nos soutiens" />
        </IMGContainer>
        <SubHeading>Signataire de la charte 2020-2021</SubHeading>
        <LogoContainer>
          <li>
            <img src={fnhpaLogo} alt="Logo FNHPA" />
          </li>
        </LogoContainer>
      </PageContainer>
    </Wrapper>
  );
};

export default PartnersSection;
