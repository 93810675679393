// @material-ui/core components
import GridBase from '@material-ui/core/Grid';
import React, { FunctionComponent } from 'react';

const GridContainer: FunctionComponent = ({ children, ...rest }) => {
  return (
    <GridBase container {...rest}>
      {children}
    </GridBase>
  );
};

export default GridContainer;
