import ContainerBase from 'components/PageContainer';
import styled from 'styled-components';

export const Container = styled(ContainerBase)`
  z-index: 12;
  color: #000000;
  padding: 30px 0px;
`;

export const HeaderLandingParkImage = styled.img`
  float: right;
  width: 400px;
  clear: right;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  margin: 70px 0px 0px;
`;

export const Title = styled.h1`
  color: #3c4858;
  margin: 1.75rem 0 0.875rem;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  position: relative;
  margin-top: 30px;
  min-height: 32px;
`;

export const Main = styled.div`
  background: #ffffff;
  position: relative;
  z-index: 3;
`;
