import { Typography } from '@material-ui/core';
import { primaryColor } from 'assets/theme/colors';
import ContainerBase from 'components/PageContainer';
import styled from 'styled-components';

export const Main = styled.div`
  background: #ffffff;
  min-height: calc(100vh - 70px - 100px);
`;

export const Container = styled(ContainerBase)`
  margin-top: 70px;
  background: #ffffff;
  padding: 120px 15px 15px 15px;
`;

export const Title = styled(Typography)`
  color: ${primaryColor};
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 50px;
`;

export const Image = styled.img`
  float: right;
  width: 400px;
  clear: right;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const Text = styled(Typography)`
  font-size: 25px;
  margin-bottom: 30px;
`;

export const Sign = styled(Typography)`
  font-size: 18px;
  font-style: italic;
  margin-bottom: 50px;
  color: ${primaryColor};
`;
