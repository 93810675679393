import quoteIcon from 'assets/img/quote.svg';
import { primaryColor } from 'assets/theme/colors';
import styled from 'styled-components';

// This is a text container with quotes

export const Container = styled.div`
  border: 5px solid #efefef;
  border-radius: 20px;
  padding: 25px 15px 15px;
  margin-top: 30px;
  margin-bottom: 10px;
  p {
    font-size: 1.2em;
  }
  &:before {
    display: block;
    content: url(${quoteIcon});
    background-size: 30px;
    height: 30px;
    width: 30px;
    margin-top: -40px;
    & svg path {
      fill: ${primaryColor};
    }
  }
`;
