import { InputLabel } from '@material-ui/core';
// @material-ui/core components
import Rating from '@material-ui/lab/Rating';
// core components
import CustomInput from 'components/CustomInput/CustomInput';
import Error from 'components/Error';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { FunctionComponent, ReactElement } from 'react';
import { Control, Controller, FieldErrors, FormState } from 'react-hook-form';
import { ParkApplicationValues } from 'types/parkApplication';

interface Props {
  control: Control;
  errors: FieldErrors;
  formState: FormState<ParkApplicationValues>;
}

const AdditionalInformationsFormSection: FunctionComponent<Props> = ({
  control,
  errors,
  formState,
}) => {
  return (
    <React.Fragment>
      <h2>Information du camping</h2>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="name"
            control={control}
            labelText="Nom du parc"
            error={!!errors.name && formState.touched.name}
            success={!errors.name && formState.touched.name}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'name',
            }}
          />
          {errors.name && <Error message={errors.name.message} />}
          <CustomInput
            name="chain"
            control={control}
            labelText="Chaine du parc"
            error={!!errors.chain && formState.touched.chain}
            success={!errors.chain && formState.touched.chain}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'chain',
            }}
          />
          {errors.chain && <Error message={errors.chain.message} />}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <InputLabel>Nombre d{"'"}étoiles du parc</InputLabel>
          <Controller
            control={control}
            name="ranking"
            defaultValue={0}
            render={({ onChange, onBlur, value, name, ref }): ReactElement => {
              return (
                <Rating
                  name={name}
                  size="large"
                  ref={ref}
                  value={value || 0}
                  onChange={(_e, ranking): void => onChange(ranking || 0)}
                  onBlur={onBlur}
                />
              );
            }}
          />
          {errors.ranking && <Error message={errors.ranking.message} />}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default AdditionalInformationsFormSection;
