import damageInsuranceIcon from 'assets/img/damage_insurance_icon.svg';
import insuranceIcon from 'assets/img/insurance_icon.svg';
import izicampTeam from 'assets/img/izicampteam.svg';
import optimizationIcon from 'assets/img/optimization_icon.svg';
import phoneOperatorIcon from 'assets/img/phone_operator_icon.svg';
import secureIncomeIcon from 'assets/img/secure_income_icon.svg';
import timeIcon from 'assets/img/time_icon.svg';
import React, { FunctionComponent } from 'react';

import {
  BulletPoint,
  BulletPointIcon,
  Description,
  Drawing,
  List,
  SubHeading,
  Swiper,
  SwiperCard,
  Wrapper,
} from './style';

const RentalMobileSection: FunctionComponent = () => {
  return (
    <Wrapper>
      <SubHeading>izicamp.fr pour vos locatifs</SubHeading>
      <Swiper
        startIndex={0}
        items={[
          <SwiperCard key={1}>
            <Description>
              Avec{' '}
              <strong>
                izicamp<span>.fr</span>
              </strong>
              , pas de droit d{"'"}entrée, pas d{"'"}abonnement, pas de surcommission.
            </Description>
            <Drawing>
              <img src={izicampTeam} alt="" />
            </Drawing>
          </SwiperCard>,
          <SwiperCard key={2}>
            <Description>
              Pour 17% HT de commission sur les séjours vendus pour votre compte,{' '}
              <strong>
                izicamp<span>.fr</span>
              </strong>
            </Description>
            <List>
              <BulletPoint>
                <BulletPointIcon src={secureIncomeIcon} alt="" />
                <p>
                  <strong>Sécurise vos revenus</strong> en séquestrant les montants encaissés sur
                  Stripe
                </p>
              </BulletPoint>
              <BulletPoint>
                <BulletPointIcon src={optimizationIcon} alt="" />
                <p>
                  <strong>Optimise la vente de vos séjours</strong> en communiquant largement pour
                  vous
                </p>
              </BulletPoint>
              <BulletPoint>
                <BulletPointIcon src={timeIcon} alt="" />
                <p>
                  <strong>Vous offre du temps</strong> en gérant toutes vos opérations commerciales
                </p>
              </BulletPoint>
            </List>
          </SwiperCard>,
          <SwiperCard key={3}>
            <Description>
              En résumé,{' '}
              <strong>
                izicamp<span>.fr</span>
              </strong>{' '}
              c{"'"}est :<br /> nouveau, moins cher et plus de services
            </Description>
            <List>
              <BulletPoint>
                <BulletPointIcon src={phoneOperatorIcon} alt="" />
                <p>
                  <strong>Informe et fidélise les vacanciers</strong> en répondant à leurs questions
                  7j/7 de 10h à minuit
                </p>
              </BulletPoint>
              <BulletPoint>
                <BulletPointIcon src={insuranceIcon} alt="" />
                <p>
                  <strong>Assure les vacanciers (et votre remplissage)</strong> en leur offrant l
                  {"'"}assurance annulation séjour <strong>CampezCouvert</strong>
                </p>
              </BulletPoint>
              <BulletPoint>
                <BulletPointIcon src={damageInsuranceIcon} alt="" />
                <p>
                  <strong>Assure les dommages causés par les vacanciers</strong> en incluant dans
                  leur séjour une assurance dommage
                </p>
              </BulletPoint>
            </List>
          </SwiperCard>,
        ]}
      />
    </Wrapper>
  );
};

export default RentalMobileSection;
