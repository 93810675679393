import { Card as CardBase, CardHeader, Typography } from '@material-ui/core';
import { primaryColor } from 'assets/theme/colors';
import styled from 'styled-components';

export const Card = styled(CardBase)`
  margin-bottom: 30px;
`;

export const HeaderTitle = styled(CardHeader)`
  background-color: ${primaryColor};
  color: #ffffff;
`;

export const Subheader = styled(Typography)`
  color: #ffffff;
  font-size: 15px;
`;

export const Title = styled(Typography)`
  font-size: 15px;
  padding-left: 16px;
  padding-top: 10px;
`;

export const Text = styled(Typography)`
  font-size: 20px;
  padding-left: 16px;
`;

export const CardFooter = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: ${primaryColor};
  color: #ffffff;
`;

export const CardFooterText = styled(Typography)`
  font-size: 20px;
`;
