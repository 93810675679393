import drawingLoyalty from 'assets/img/drawing_loyalty.svg';
import drawingSecurity from 'assets/img/drawing_security.svg';
import drawingVisibility from 'assets/img/drawing_visibility.svg';
import React, { FunctionComponent } from 'react';

import { Card, CardContainer, PageContainerModified, SubHeading } from './style';

const ReinsuranceSection: FunctionComponent = () => {
  return (
    <PageContainerModified>
      <SubHeading>Avec izicamp.fr vous gagnez en...</SubHeading>
      <CardContainer>
        <Card>
          <h3>Visibilté</h3>
          <p>de vos locatifs</p>
          <img src={drawingVisibility} alt="" />
        </Card>
        <Card>
          <h3>Sécurité</h3>
          <p>de vos revenus locatifs</p>
          <img src={drawingSecurity} alt="" />
        </Card>
        <Card>
          <h3>Fidelité</h3>
          <p>de vos vacanciers</p>
          <img src={drawingLoyalty} alt="" />
        </Card>
      </CardContainer>
    </PageContainerModified>
  );
};

export default ReinsuranceSection;
