// Create AppBar components before
import AppBarBase from '@material-ui/core/AppBar';
import ToolBarBase from '@material-ui/core/Toolbar';
import React from 'react';
import styled from 'styled-components';

interface OverridedAppBarProps {
  fixed: boolean;
  absolute: boolean;
  displayShadow: boolean;
}

export const ToolBar = styled(ToolBarBase)`
  justify-content: space-between;
  padding: 0px;
  min-height: 50px;
`;

export const AppBar = styled(({ displayShadow, absolute, fixed, children, ...rest }) => (
  <AppBarBase {...rest}>{children}</AppBarBase>
))<OverridedAppBarProps>`
  padding: 0.625rem 0;
  width: 100%;
  background-color: #fff;
  transition: all 150ms ease 0s;
  ${({ displayShadow }) =>
    displayShadow
      ? `
      box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
    `
      : `
      box-shadow: none;
    `}
  ${({ absolute }) =>
    absolute &&
    `
      position: absolute;
    `}
  ${({ fixed }) =>
    fixed &&
    `
      position: fixed;
    `}
`;
