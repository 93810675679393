import React, { Fragment, FunctionComponent } from 'react';

import Error from '../index';

// Styles
// import { Container } from './style';

interface MutationError {
  type: string;
  message: string;
  path: Array<string>;
}

interface Props {
  errors: Array<MutationError> | null;
}

const MutationErrors: FunctionComponent<Props> = ({ errors }) => {
  return (
    <Fragment>
      {errors &&
        errors.map((error: MutationError) => <Error key={error.message} message={error.message} />)}
    </Fragment>
  );
};

export default MutationErrors;
