import React, { FunctionComponent } from 'react';

// core components
import PaginationDot from './components/PaginationDot';
import { Container } from './style';

interface Props {
  index: number;
  dots: number;
  onChangeIndex: (index: number) => void;
}

const PaginationDots: FunctionComponent<Props> = ({ index, dots, onChangeIndex }) => {
  const handleClick = (newIndex: number) => {
    onChangeIndex(newIndex);
  };

  const children = [];

  for (let i = 0; i < dots; i += 1) {
    children.push(<PaginationDot key={i} index={i} active={i === index} onClick={handleClick} />);
  }

  return <Container>{children}</Container>;
};

export default PaginationDots;
